<template>
  <div class="facial-features q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['prominentBloodVessels'])">
      <single-answer-question
        title="label.prominentBloodVessels"
        :value="prominentBloodVessels"
        :options="prominentBloodVesselsOptions"
        dysfunction="skin-redness"
        yes-no
        @input="onFieldChange('prominentBloodVessels', $event)"
      />
    </question-with-error-wrap>

    <question-divider
      v-if="isQuestionDividerVisible"
      class="q-mt24 q-mb32 q-mt32-md q-mb40-md"
      title="questionnaire.tellUsMore.title"
    />

    <fade-in-transition-wrap>
      <question-with-error-wrap
        v-if="rednessDetailQuestionsVisible"
        class="q-mt24 q-mt32-md"
        :error="getFieldsError(['firstBloodVesselOccurrance'])"
      >
        <single-answer-question
          id="facial-capillary-occurrence"
          title="label.initialCapillaryOccurrence"
          :value="firstBloodVesselOccurrance"
          :options="$options.rednessDurationOptions"
          dysfunction="skin-redness"
          @input="onFieldChange('firstBloodVesselOccurrance', $event)"
        />
      </question-with-error-wrap>
    </fade-in-transition-wrap>

    <fade-in-transition-wrap>
      <question-with-error-wrap
        v-if="rednessDetailQuestionsVisible"
        class="q-mt24 q-mt32-md"
        :error="getFieldsError(['bloodVesselLocations'])"
      >
        <face-zone-multi-select
          id="facial-capillary-locations"
          title="label.capillaryLocations"
          :value="bloodVesselLocations"
          :options="$options.capillaryFaceLocations"
          :gender="gender"
          dysfunction="skin-redness"
          hint="label.selectAllRegionsThatApply"
          field-name="blood-vessels"
          @input="onFieldChange('bloodVesselLocations', $event)"
        />
      </question-with-error-wrap>
    </fade-in-transition-wrap>
  </div>
</template>

<script>
import QuestionDivider from '@/modules/questionnaire/components/common/QuestionDivider';
import FadeInTransitionWrap from '@/components/wrappers/FadeInTransitionWrap';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import FaceZoneMultiSelect from '@/modules/questionnaire/new-design-components/questions/face-zone-input/FaceZoneMultiSelect';

import { flagAiSelectedOption } from '@/modules/questionnaire/api/helpers';
import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { CAPILLARY_FACE_LOCATION_OPTIONS } from '@/modules/questionnaire/constants/steps/skinRedness';
import { REDNESS_DURATION } from '@/modules/questionnaire/api/constants';
import { YES_NO_RESPONSE_OPTIONS } from '@/modules/questionnaire/constants/questions';

const REDNESS_DURATION_OPTIONS = [
  {
    value: REDNESS_DURATION.UNDER_A_YEAR,
    text: 'capillaryOccurrence.underAYear'
  },
  {
    value: REDNESS_DURATION.OVER_THREE_YEARS,
    text: 'capillaryOccurrence.moreThanTwoYears'
  }
];

export default {
  name: 'CapillariesTemplate',
  components: {
    FadeInTransitionWrap,
    QuestionDivider,
    QuestionWithErrorWrap,
    SingleAnswerQuestion,
    FaceZoneMultiSelect
  },
  yesNoOptions: YES_NO_RESPONSE_OPTIONS,
  rednessDurationOptions: REDNESS_DURATION_OPTIONS,
  capillaryFaceLocations: CAPILLARY_FACE_LOCATION_OPTIONS,
  mixins: [stepTemplateMixin],
  props: {
    gender: {
      type: String,
      default: ''
    },
    prominentBloodVessels: {
      type: String,
      default: ''
    },
    prominentBloodVesselsAiSelected: {
      type: String,
      default: ''
    },
    firstBloodVesselOccurrance: {
      type: String,
      default: ''
    },
    bloodVesselLocations: {
      type: Array,
      default: () => []
    },
    rednessDetailQuestionsVisible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    prominentBloodVesselsOptions() {
      return flagAiSelectedOption(YES_NO_RESPONSE_OPTIONS, this.prominentBloodVesselsAiSelected);
    },
    isQuestionDividerVisible() {
      return this.rednessDetailQuestionsVisible;
    }
  }
};
</script>
